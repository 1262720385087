/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 64.4.4-v202412131609-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqExportApiClass {
 
   constructor() {}

  /**
   * @summary Archive an export
   * @param {string} id - ID of the export to archive
   */
  public archiveExport(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/export/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ExportItemsOutputV1>;
  }

  /**
   * @summary Archive any exports that have not been updated or used in the timeframe provided. This may only be performed by administrators.
   * @param {string} duration=90 days - Archive any exports older than &#x27;duration&#x27;
   */
  public archiveExportsOlderThan(
    {
      duration
    } : {
      duration: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(duration)) {
      throw new Error("'duration' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/export/cleanup`,

      params: omitBy({
        ['duration']: duration
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Export data in the specified output format. Currently only xlsx and odata are supported.
   */
  public createExport(
    body: models.ExportItemsV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/export`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<Blob>;
  }

  /**
   * @summary Get the details of an export
   * @param {string} id - ID of the export to retrieve
   */
  public getExport(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/export/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ExportItemsOutputV1>;
  }

  /**
   * @summary Get a collection of exports
   * @param {boolean} [includeArchived=false] - Whether archived exports should be included
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getExports(
    {
      includeArchived,
      offset,
      limit
    } : {
      includeArchived?: boolean,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/export`,

      params: omitBy({
        ['includeArchived']: includeArchived,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ExportPreviewListV1>;
  }

  /**
   * @summary Update the details of an export
   * @param {string} id - ID of the export to update
   */
  public updateExport(
    body: models.ExportItemsV1,
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/export/${encodeURIComponent(String(id))}`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ExportItemsOutputV1>;
  }

}


export const sqExportApi = new sqExportApiClass();
